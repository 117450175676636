<template>
  <div>
    <div class="flex items-center justify-between">
      <slot name="title" />

      <VDropdown
        dropdown-menu-class="w-96"
        :selected="selectedStatus"
        :options="statusOptions"
        @click="selectedStatus = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 flex justify-between items-center">
            <div class="truncate w-2/3">
              {{ option.text }}
            </div>

            <div
              v-if="option.value !== 'all'"
              class="rounded-full w-3 h-3"
              :style="{ 'background-color': getRGBStatusColor(option.value) }"
            />
          </div>
        </template>
      </VDropdown>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="data.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <div v-for="(evaluation, index) in data" :key="index">
          <EvaluationCard
            :resource="evaluation"
            :evaluation-type="type"
            @refresh="getData"
            @click="onClickRedirectToEvaluation"
          />
        </div>
      </div>

      <FullPagination
        :pagination="pagination"
        @update:per_page="onUpdatePerPage"
        @update:current_page="onUpdateCurrentPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useOptions from "@/composables/useOptions";
import useColor from "@/composables/useColor";
import usePaginate from "@/composables/usePaginate";
// Components
import EvaluationCard from "./EvaluationCard";
import FullPagination from "@/components/tables/FullPagination";
import VAlert from "@/components/VAlert";
import VDropdown from "@/components/VDropdown";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    EvaluationCard,
    VAlert,
    FullPagination,
    VDropdown
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.values(evaluationTypes).includes(value)
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { ALL_OPTIONS } = useOptions();
    const { getRGBStatusColor } = useColor();

    // Data
    const selectedStatus = ref("all");

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${props.title} - ${t("app.evaluations", 2)} - ${t("app.learning")}`;
    const statusOptions = [
      {
        text: t("app.all_statuses"),
        value: "all"
      },
      ALL_OPTIONS.PENDING,
      ALL_OPTIONS.IN_PROGRESS,
      ALL_OPTIONS.PAUSED,
      ALL_OPTIONS.OBSERVATION_REQUESTED,
      ALL_OPTIONS.OBSERVATION_SCHEDULED,
      ALL_OPTIONS.LEARNER_COMPLETED,
      ALL_OPTIONS.COMPLETION_APPROVAL_REQUESTED,
      ALL_OPTIONS.COMPLETED,
      ALL_OPTIONS.WITHDRAWN,
      ALL_OPTIONS.CANCELLED
    ];

    // Methods
    const getData = async () => {
      let queryParams = {};

      if (selectedStatus.value !== "all") {
        queryParams = {
          ro_f: JSON.stringify([
            {
              f: "status",
              c: "EQUAL",
              v: [selectedStatus.value]
            }
          ])
        };
      }

      await read({
        endpoint: "learning.evaluations.index",
        pathParams: {
          type: props.type
        },
        queryParams: {
          ...queryParams,
          ro_r: ["approver"]
        }
      });
    };

    const onClickRedirectToEvaluation = (id, type) => {
      const types = {
        [evaluationTypes.QUIZ]: "quizzes",
        [evaluationTypes.OBSERVATION]: "observations"
      };

      router.push({
        name: `learning-${types[type]}-details`,
        params: { id }
      });
    };

    // Composables
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(
      pagination,
      getData
    );

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(selectedStatus, async () => {
      await getData();
    });

    return {
      documentTitle,
      selectedStatus,
      statusOptions,
      getData,
      onClickRedirectToEvaluation,
      // useColor
      getRGBStatusColor,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>
